import React, { useEffect, useState } from 'react'
import { ONEDAYINSECONDS } from '../config/env';
import { getAccount } from '../hooks/useAccount';
import { OnStake } from '../hooks/UseStake';


const StakingFour = (props) => {

  // console.log("StakingFour...", props?.amount, Math.round(props?.amount))
  const [pooldays, setpoolDays] = useState()
  const [check, setCheck] = useState()
  const [pendingTx, setpendingTx] = useState(true);
  const [status, setStatus] = useState(false);
  useEffect(() => {
    var poolday = parseInt(props?.poolLockedDays?.lockedPeriod) / ONEDAYINSECONDS;
    // console.log("POOLDAYS..", poolday);
    setpoolDays(poolday.toFixed())
  }, [props])


  const depositTokens = async () => {
    const pid = props?.selectPairPid;
    const decimal = props?.selectStakeToken?.decimal;
    const deposit = Math.round(props?.amount)
    const planId = props?.pid
    // console.log("DEPOSIT TOKEN",pid,decimal,deposit,planId )
    await OnStake(getAccount(), planId, deposit, decimal,pid)
    setpendingTx(false)
  }


  return (
    <div className='row'>
      <div className='col-12 col-sm-9 col-md-7 col-lg-6 col-xl-4 col-xl-custom mx-auto'>
        <div className="card shadowstyle px-3 pt-0 pb-4">
          <div className="card-body">

            <div className='row'>
              <div className='col-md-12 mb-3'>
                <p className="card-title mb-0">Summary</p>


              </div>
            </div>
            <div className='row row_progress_summary'>
              <div className='col-md-6 mb-0 col_left_prog'>
                <p className='white_text_sm'>Amount</p>
              </div>
              <div className='col-md-6 mb-0'>
                <p className='white_text_sm'> <span className="dot_double_por">:</span> {props?.amount}</p>
              </div>
            </div>
            <div className='row row_progress_summary'>
              <div className='col-md-6 mb-0 col_left_prog'>
                <p className='white_text_sm'>Staked Token</p>
              </div>
              <div className='col-md-6 mb-0'>
                <p className='white_text_sm'><span className="dot_double_por">:</span> {props?.selectStakeToken?.token}</p>
              </div>
            </div>
            <div className='row row_without_circle_summary'>
              <div className='col-md-6 mb-0 col_left_prog'>
                <p className='white_text_sm'>Days</p>
              </div>
              <div className='col-md-6 mb-0'>
                <p className='white_text_sm'><span className="dot_double_por">:</span> {parseInt((props?.poolLockedDays?.lockedPeriod * 1000) / (1000 * 60 * 60 * 24))} Day</p>
              </div>
            </div>
            <div className='row row_progress_summary'>
              <div className='col-md-6 mb-0 col_left_prog'>
                <p className='white_text_sm'>Reward</p>
              </div>
              <div className='col-md-6 mb-0'>
                {/* {console.log("pooldays",pooldays)} */}
                <p className='white_text_sm'><span className="dot_double_por">:</span> {((props?.poolLockedDays?.UmudiPerBlock / 10 ** 18) * Number(pooldays)).toFixed(2)}% APY </p>
              </div>
            </div>
            <div className='row row_progress_summary'>
              <div className='col-md-6 mb-0 col_left_prog'>
                <p className='white_text_sm'>Deposit Fee</p>
              </div>
              <div className='col-md-6 mb-0'>
                <p className='white_text_sm'><span className="dot_double_por">:</span> {parseInt(props?.selectPool?.depositFeeBP) / 100}%</p>
              </div>
            </div>

            <div className='row row_progress_summary'>
              <div className='col-md-6 mb-0 col_left_prog'>
                <p className='white_text_sm'>Reward Token</p>
              </div>
              <div className='col-md-6 mb-0'>
                <p className='white_text_sm'><span className="dot_double_por">: </span>{props?.rewardToken?.token}</p>
              </div>
            </div>


            {/* <div className='row row_progress_summary row_progress_summary_last'>
   <div className='col-md-6 mb-0 col_left_prog'>
    <p className='white_text_sm'>Redemption Date</p>
    </div>
   <div className='col-md-6 mb-0'>
    <p className='white_text_sm'><span className="dot_double_por">:</span> Feb 6 2023 05:00 AM</p>
   </div>
 </div> */}


            {/* <div className='row row_normal_summary'>
   <div className='col-md-6 mb-0 col_left_prog'>
    <p className='white_text_sm'>Est. APY</p>
   </div>
   <div className='col-md-6 mb-0'>
    <p className='white_text_sm'><span className="dot_double_por">:</span> 42.5% APY</p>
   </div>
 </div> */}
            {/* <div className='row row_normal_summary'>
   <div className='col-md-6 mb-0 col_left_prog'>
    <p className='white_text_sm mb-0'>Est. Interest 200 RIA</p>
   </div>
   <div className='col-md-6 mb-0'>
    <p className='white_text_sm mb-0'><span className="dot_double_por">:</span> Reward $500 ETH</p>
   </div>
 </div> */}
          </div>

          <div className='agree_sec'>
            <div className="custom-control custom-checkbox d-flex">
              <input type="checkbox" className="custom-control-input" id="customCheck1" checked={check} onClick={() => { setCheck(!check) }} />
              <label className="custom-control-label ps-2" for="customCheck1">
                <span>I agree with</span>
                <a href="#" className='headright_a px-1'>Terms</a>
                <span>and</span>
                <a href="#" className='headright_a px-1'>Privacy</a>
              </label>
            </div>
          </div>

          <div className='stakingbtnsec mt-4 pb-2'>
            <button className="btn_yellow mx-3 mb-3 mb-md-0" onClick={() => { props.sendDataToParent(2, props?.data, props?.userTokenBalance, props?.pid, false, props?.selectPool, props?.amount,0,0,props?.poolLockedDays) }}>Back</button>
            
            {
              pendingTx ? 
            <button disabled={!check}  className='btn_yellow mb-3 mb-md-0' onClick={async () => { setStatus(true); setpendingTx(true); await depositTokens();props.sendDataToParent(5)}}>{status ? 'Depositing' : 'Deposit'}  </button> : 
            <button disabled={!check} className="btn_yellow mb-3 mb-md-0" onClick={async () => { props.sendDataToParent(5,props?.data, props?.userTokenBalance, props?.pid, false, props?.selectPool, props?.amount,0,0,props?.poolLockedDays)  }}>Submit</button>
            }
           
            
          </div>




        </div>
      </div>
    </div>

  )
}

export default StakingFour
