import { MASTERCHEF_ABI } from "../config/Masterchef";
import { ERC20_ABI } from "../config/erc20";
import toast, { Toaster } from 'react-hot-toast';
import { GetChainIndex, useWeb3 ,usehttpProvider} from "./useWeb3"
import { CHAINS } from "../config/env";
import BigNumber from 'bignumber.js';

export const UseMasterChef = async()=>{
    try{
    const web3 = await useWeb3();
    const contract = new web3.eth.Contract(MASTERCHEF_ABI, getMasterChefAddress());
   
    return contract;
}catch (e) {
               
}  
}

export const UsehttpMasterChef = async()=>{
    try{
        // console.log("log");
    const web3 = await usehttpProvider();
    const contract = new web3.eth.Contract(MASTERCHEF_ABI, getMasterChefAddress());
   
    return contract;
}catch (e) {
               
}  
}

export const UseERC20 = async (VALUE) => {
    try{
    const web3 = await useWeb3();
    // const web3 = await usehttpProvider();
    const Tokencontract = new web3.eth.Contract(ERC20_ABI, VALUE);
    return Tokencontract;
}catch (e) {
               
}  
}

export const UseHttpERC20 = async (VALUE) => {
    try{
    const web3 = await usehttpProvider();
    const Tokencontract = new web3.eth.Contract(ERC20_ABI, VALUE);
    return Tokencontract;
}catch (e) {
               
}  
}

export const UseUserBalance = async(TOKEN,account)=>{
    try{
    const erc20 = await UseHttpERC20(TOKEN);
    const balance = await erc20.methods.balanceOf(account).call();
    return balance;
}catch (e) {
               
}
}


export const getStakedBalance = async(account)=>{
    
    // const stake = await UseMasterChef();
    const stake = await UsehttpMasterChef()

    
}

export const  sleep = (ms) => 
{
    new Promise(resolve => setTimeout(resolve, ms))
}


export const getNativeToken =()=>{
    const index = GetChainIndex();
    return CHAINS[index].NATIVE;
}

// export const getMulticallAddress = ()=>{
//     const index = GetChainIndex();
//     return CHAINS[index].MULTICALL;
// }

export const getMasterChefAddress = ()=>{
    try{
    const index = GetChainIndex();
    const ContractAddress = CHAINS[index].MASTERCHEF;
 
    return ContractAddress;
}catch (e) {
               
}
}


export const checkIsApproved = async (account,token) => {
    // console.log("checkIsApproved",account,token)
    try{
    const erc20contract = await UseHttpERC20(token);
    const allow = await erc20contract.methods.allowance(account,getMasterChefAddress()).call();
   
    return (parseInt(allow)>0)?true:false;
}catch (e) {
               
}
}

export const approveContract = async(account,token)=>{
   
    const erc20Contract = await UseERC20(token);
    const data = erc20Contract.methods.approve(getMasterChefAddress(),"115792089237316195423570985008687907853269984665640564039457584007913129639935").send({ from: account})
    await toast.promise(data, {
     loading: 'Approving...',
     success: 'Approved Successfully',
     error: 'Try Again',
     }, {
     style: {
     minWidth: '300px',
     minHeight: '55px'
     }
     })
}

export const UseTokenInfo = async (TOKEN) => {
    try{
    const token = await UseHttpERC20(TOKEN);
    const name= await token.methods.name().call();
    const symbol= await token.methods.symbol().call();
    const decimals= await token.methods.decimals().call();
    const data = {
        name: name,
        symbol: symbol,
        decimals: decimals,
        address: TOKEN
    }
   
    return data;
}catch (e) {
               
}
   
   

}


export const getUserBalance = async (token,account) => {
    // console.log("token,account",token,account)
    try{
    const tokenContract = await UseHttpERC20(token);
    const tokenInfo = await UseTokenInfo(token);
    const balance = await tokenContract.methods.balanceOf(account).call();
    const balanceTokens = ((new BigNumber(balance)).div(new BigNumber(10).pow(tokenInfo.decimals))).toNumber();
    // console.log("BALANCETOKENS",balanceTokens );
    return balanceTokens.toString();
}catch (e) {
               
}}

export const toFixedNumber = (x)=> {
    if (Math.abs(x) < 1.0) {
        var e = parseInt(x.toString().split('e-')[1]);
        if (e) {
            x *= Math.pow(10, e - 1);
            x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
        }
    } else {
        var e = parseInt(x.toString().split('+')[1]);
        if (e > 20) {
            e -= 20;
            x /= Math.pow(10, e);
            x += (new Array(e + 1)).join('0');
        }
    }
    return x;
}


export const searchedValue = async(tokenList,searchSymbol) => {
    // console.log("searchedValue",tokenList,searchSymbol)
    const filteredData  = await tokenList.filter(value => ((value.lockedPeriod)).includes(searchSymbol));
    return filteredData;
}

