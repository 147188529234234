import React, { useEffect, useState } from 'react';
import { Link, NavLink } from "react-router-dom"
import { NavDropdown, Navbar, Container, Nav } from 'react-bootstrap'
import WalletModal from "./WalletModal"

import logo from '../images/logo.png'

const Header = (props) => {
    

    const [wallet, setShowWallet] = useState(false);
    const [accountInfo, setAccountInfo] = useState("");

    useEffect(() => {
        const walletAddress = sessionStorage.accountInfo ? setAccountInfo(sessionStorage.getItem("accountInfo")) : ""
    }, [accountInfo]);

    const logOut = async () => {
        setAccountInfo("");
        sessionStorage.removeItem("accountInfo");
        window.location.reload()
    }

    return (
        <div id="header">
            <div className='container container_custom'>
                <Navbar expand="lg">

                    <Navbar.Brand>
                        <NavLink to="/" className="navbar-brand" >
                            <img src={logo} className="img-fluid logoheader" />
                        </NavLink>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <ul className="navbar-nav">
                            <li className="nav-item ml-auto">
                                <NavLink to="/calculate" className="nav-link">Calculate</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/staking" className="nav-link" state={{ from: accountInfo }}>Staking</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/lockedstaking" className="nav-link">Plans</NavLink>
                            </li>
                            {/* <li className="nav-item">
                                <a href="/" className="nav-link">Market</a>
                            </li> */}
                            {sessionStorage.getItem("accountInfo") == null || undefined || "" ? 
                        <>
                        </>
                        : <>
                        <li className="nav-item">
                                <NavLink to="/rewards" className="nav-link">My Rewards</NavLink>
                            </li>
                        </>
                        }
                            <li className="nav-item">
                                {
                                    (accountInfo == "" || accountInfo == null) ?
                                        <button className="nav-link btn_yellow" onClick={() => { setShowWallet(true) }}>Connect Wallet</button> :
                                        <button className="nav-link btn_yellow" onClick={() => { logOut() }}>{`${accountInfo.substring(0, 5)}...${accountInfo.substring(38, 42)}`}</button>
                                }
                                {/* <button className="nav-link btn_yellow" onClick={()=>{setShowWallet(true)}}>Connect Wallet</button> */}
                            </li>
                        </ul>
                    </Navbar.Collapse>

                </Navbar>

            </div>
            {wallet && <WalletModal onDismiss={() => setShowWallet(false)} />}

        </div>
    )
}

export default Header