import React, { useEffect, useState } from 'react'
import Header from './Header'
import LockedStakingTable from './LockedStakingTable'

import { Link } from "react-router-dom";
import Badge from 'react-bootstrap/Badge';
import eth_bal from '../images/eth_bal.png';
import eth_bal_price from '../images/eth_bal_price.png';
import eth from '../images/eth.png';
import { sleep, UseTokenInfo, checkIsApproved, approveContract, getUserBalance } from "../hooks/UseContract"
import { getRewardTokenInfo, getUser, getUserReward, Claim, getUSDTprice, getPoolLength, getPoolInfoLimit, FARMS } from "../hooks/UseStake"
import { ONEDAYINSECONDS } from '../config/env';
import leaf from '../images/leaf.png'
import { getAccount } from '../hooks/useAccount';

const Lockedstaking = () => {

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true);
  const [pagination, setpagination] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(15);
  const [poolLength, setPoolLength] = useState(0);
  const [lpsymbol, setlpsymbol] = useState({})
  const [poolLimit, setPoolLimit] = useState([]);
  const [sortField, setSortField] = useState("");
  const [sort, setSort] = useState({ order: 'asc', orderBy: 'id' })
  const [tableData, setTableData] = useState();
  const [activePage, setActivePage] = useState(1)
  const [filters, setFilters] = useState({})
useEffect(() => {
  showloader()
  getData()
},[])

const getData = async () => {
  var data = await getPoolLength()
  if (data) setPoolLength(data);
  var pool = await getPoolInfoLimit(0, data)
  setlpsymbol(FARMS)
  if (pool) {
    setData(pool)
  }
}

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(data.length / recordsPerPage)
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1)
  const columns = [
    { label: "#", accessor: "#"},
    { label: "StakedDays", accessor: "StakedDays"},
    { label: "RewardsAPY %", accessor: "RewardsAPY %"},
    { label: "Stake Fee", accessor: "Stake Fee"},
    // { label: "StakeToken", accessor: "StakeToken"},
   ];


  const nextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1)
  }
  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1)
  }
  const showloader = async () => {
    document.getElementById("loader_div").style.display = "block";
  }

  const hideloader = async () => {
    document.getElementById("loader_div").style.display = "none";
  }
  
  const handleSort = accessor => {
    // console.log("accessor",accessor)
    setActivePage(1)
    setSort(prevSort => ({
      order: prevSort.order === 'asc' && prevSort.orderBy === accessor ? 'desc' : 'asc',
      orderBy: accessor,
    }))
  }

  // const sortRows = (rows, sort) => {
  //   return rows.sort((a, b) => {
  //     const { order, orderBy } = sort
  
  //     if (isNil(a[orderBy])) return 1
  //     if (isNil(b[orderBy])) return -1
  
  //     const aLocale = convertType(a[orderBy])
  //     const bLocale = convertType(b[orderBy])
  
  //     if (order === 'asc') {
  //       return aLocale.localeCompare(bLocale, 'en', { numeric: isNumber(b[orderBy]) })
  //     } else {
  //       return bLocale.localeCompare(aLocale, 'en', { numeric: isNumber(a[orderBy]) })
  //     }
  //   })
  // }

  return (
    <section>
       <div className='showloader minwidth-534 text-center' id="loader_div">
        <div className='loader ' >
          <img src={leaf} className="spin" />
          {/* <img src={leaf} className="leaf" /> */}
        </div>
      </div>
      {/* {console.log("sorting..",sort)} */}
  <div className='innerpages lockedstaking pb-4'>
    <Header />
    <div className='container container_custom mt-5 pt-main-top'>
      <div className='headsec mb-3'>
        <div>
          <h3 className='headtitle'>Locked Staking</h3>
        </div>
        <div>
          <Link to="/calculate" className='headright'>ROI Calculator</Link>
        </div>
      </div>
      <div className='cardsec'>
        <div className='secondcard mb-4'>
          <div class="card shadowstyle">
            <div class="card-body">
            <div className='tablesec'>
                <table class="table">
                  {/* <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Staked Days<i class="fa fa-sort" aria-hidden="true"></i></th>
                      <th scope="col">Rewards APY %<i class="fa fa-sort" aria-hidden="true"></i></th>
                      <th scope="col">Earn <i class="fa fa-sort" aria-hidden="true"></i></th>
                      <th scope="col">Stake Token <i class="fa fa-sort" aria-hidden="true"></i></th>
                    </tr>
                  </thead> */}

                      <thead>
                        <tr>
                          {columns.map(column => {
                            const sortIcon = () => {
                              if (column.accessor === sort.orderBy) {
                                if (sort.order === 'asc') {
                                  // return <i class="fas fa-sort-down" aria-hidden="true" />
                                }
                                // return <i class="fas fa-sort-up" aria-hidden="true" />
                              } else {
                                // return  <i class="fas fa-sort" aria-hidden="true" />
                              }
                            }

                            return (
                              <th key={column.accessor}>
                                <span>{column.label}</span> &nbsp;
                                <a onClick={() => handleSort(column.accessor)}>{sortIcon()}</a>
                              </th>
                            )
                          })}
                        </tr>
                      </thead>
                      {/* {console.log("currentRecords",currentRecords)} */}
                      {
                    currentRecords && currentRecords.map((val,index) => 
                    
                     
                        (index == 0  || index == 1 || index == 2 || index == 3  ? 
                      <LockedStakingTable  datas={val} pid={data.indexOf(val)} accountInfo={getAccount()} type={"lockedStaking"} hideloader={hideloader} index={index}/>  : <></>)
                      
                       
                    )
                  }
             
             </table>
             </div>
            </div>
          </div>
        </div>
        <div className='paginationsec'>

          <nav aria-label="...">
            <ul class="pagination">
              <li class={currentPage != 1 ?  "page-item" : "page-item disabled" }>
              {/* tabindex="-1" */}
                <a class="page-link" href="#" ><i class="fa fa-angle-left" aria-hidden="true" onClick={prevPage}></i></a>
              </li>

              {pageNumbers && pageNumbers.map((pgNumber)=> 
              <li class="page-item active" key={pgNumber}>
              <a onClick={() => setCurrentPage(pgNumber)}  className='page-link' href='#' > {pgNumber} </a>
              </li>
              )}
              {/* {console.log("currentPage",currentPage,"-----","nPages--",nPages)} */}
              <li class={currentPage != nPages ?  "page-item" : "page-item disabled" }>
                <a class="page-link" href="#"><i class="fa fa-angle-right" aria-hidden="true" onClick={nextPage}></i></a>
              </li>

            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
  </section>
  )
}

export default Lockedstaking
