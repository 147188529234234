import React, { Component, useState, useEffect, } from 'react'
import disabled from '../images/disabled.png'
// import modalbg from '../images/modalbg-min.png'
import Header from "./Header";
import Footer from "./Footer";
import WalletModal from "./WalletModal"
import PlaceholdingLoader from './PlaceholdingLoader'
import Slider from "@material-ui/core/Slider";
import { Dropdown } from 'react-bootstrap'
import {Link} from "react-router-dom"
import BigNumber from 'bignumber.js';
import logo from '../images/logo.png'
import eth from '../images/eth.png'
import bnb from '../images/bnb.png'
import busd from '../images/busd.png'

import reward_img from '../images/reward_img.png'
import graph_img from '../images/graph_img.png'
import star from '../images/star.png'


import { useWeb3 } from "../hooks/useWeb3";
import { MetamaskWallet, WalletConnect, BinanceWallet } from '../hooks/useWallet';
import toast, { Toaster } from 'react-hot-toast';
import { getRewardTokenInfo, getUser, getUserReward, Claim, getUSDTprice, getPoolLength, getPoolInfoLimit, FARMS, plan  } from "../hooks/UseStake"
import { CHAINS } from "../config/env"
import { sleep, UseTokenInfo, checkIsApproved, approveContract, getUserBalance,searchedValue,toFixedNumber } from "../hooks/UseContract"
import { ONEDAYINSECONDS } from '../config/env';
import { getAccount } from "../hooks/useAccount"
import { rewardTokenDetails,irenaLpToken,stakeTokens } from '../config/Farms';

import banner_img from '../images/banner_img.png'
import logo_coin from '../images/logo_coin.png'


import leaf from '../images/leaf.png'

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Home = () => {
    const [accountInfo, setAccountInfo] = useState("");
    const [walletConnect, set_walletConnect] = useState("")
    const [poolLength, setPoolLength] = useState(0);
    const [poolLimit, setPoolLimit] = useState([]);
    const [key, setKey] = useState('first');
    const [lpsymbol, setlpsymbol] = useState({})
    const[wallet , setShowWallet] = useState(false)  
    const [userTokenBalance, setUserTokenBalance] = useState("");
    const [perDayReward,setPerDayReward] = useState()
    const [reward,setReward] = useState(); 
    const [loading, setLoading] = useState(false);
    const [mark,setMark] = useState([]);
    const [valueone, setValueOne] = React.useState([30]);
    const [valuetwo, setValueTwo] = React.useState([0]);
    const [valuethree, setValueThree] = React.useState([20]);
    const [contentLoading, setContentLoading] = useState(false);
    const [selectStakeToken,setSelectStakeToken] = useState(stakeTokens[0]);
    const [selectRewardToken, setSelectRewardToken] = useState(rewardTokenDetails[0]);
    const [selectDayApy,setSelectDayApy] = useState();
    const [selectedDayApy,setSelectedDayApy] = useState("2.2");
    const [rewardListToken,setRewardListToken] = useState(rewardTokenDetails);
    

    const options = {
      
        responsiveClass: true,
        nav: true,
        dots: false,
        autoplay: false, 
        center:true,
        // navText: ['<i class="fa fa-long-arrow-left" aria-hidden="true"></i>','<i class="fa fa-long-arrow-right" aria-hidden="true"></i>'],
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 1,
            },
            700: {
                items: 3,
            },
            1000: {
                items: 3,    
            },
            1200: {
                items:3,
            }
        },
    };



    function addtabclass(){
        document.getElementById("tabid").classList.add("completed")
      }

useEffect(() => {
    // showloader()
    getData();
    if (sessionStorage.accountInfo) {
        setAccountInfo(sessionStorage.getItem("accountInfo"))
    } else {
        console.log("SORRY...No Wallet Address")
    }
}, [accountInfo]);

const logOut = async () => {
    setAccountInfo("");
    sessionStorage.removeItem("accountInfo");
    window.location.reload()
}


    const setTabKey = (value) => {
        setKey(value)
    }

    const handleChange = (event, newValue) => {
        setValueOne(newValue);
      };

      function valuetextone(valueone) {
        return `${valueone}`;
      }
   

      const handleChangeTwo = (event, newValue) => {
        poolDetails(newValue)
        setValueTwo(newValue);
        const selecyApy = selectDayApy.filter((el)=> parseInt(el.lable) == parseInt(newValue));
        console.log("handleChangeTwo..",selecyApy)
        setSelectedDayApy(selecyApy[0].value)
      };

      function valuetexttwo(valuetwo) {
        return `${valuetwo}`;
      }

      const handleChangeThree = (event, newValue) => {
        setValueThree(newValue);
      };

      function valuetextthree(valuethree) {
        return `${valuethree}`;
      }

      const getData = async () => {
        const userbalance = accountInfo ? await getUserBalance(irenaLpToken, getAccount()) : 1000 ;
        // console.log("userBalance", userbalance,irenaLpToken)
        setUserTokenBalance(userbalance)
        setValueOne(0);
        var arr = []
        var data = await getPoolLength()
        // console.log("data", data);
        if (data) setPoolLength(data);
        var pool = await getPoolInfoLimit(0, data)
        console.log("firstPool",pool)
        if (pool) {
          var poolArr = [];
          let daysApy = []
          for (let [index, value] of pool.entries()) {
            if(index <= 3){
                var poolDetail = await plan(index)
                console.log("poolDetail",poolDetail);
                poolArr.push(poolDetail);
            }
           
          }
          setPoolLimit(poolArr);
          console.log(poolArr, 'pool')
          setValueTwo(Math.floor(parseInt(poolArr[0]?.lockedPeriod * 1000) / (1000 * 60 * 60 * 24)))
          poolArr.map((val,index)=>{
            var marks= {
              lable : `${Math.floor(parseInt(val?.lockedPeriod * 1000) / (1000 * 60 * 60 * 24))}Days`,
              value : Math.floor(parseInt(val?.lockedPeriod * 1000) / (1000 * 60 * 60 * 24))
            }
            var selectDayApy= {
                lable : `${Math.floor(parseInt(val?.lockedPeriod * 1000) / (1000 * 60 * 60 * 24))}Days`,
                value : ((parseInt(val?.UmudiPerBlock) / 10 ** 9) * Number((val?.lockedPeriod * 1000) / (1000 * 60 * 60 * 24))/ 10 ** 9)
              }
              daysApy.push(selectDayApy)
            arr.push(marks)
          })
          setMark(arr)
          setSelectDayApy(daysApy)
          setLoading(true);
        //   console.log("valuetwo in getdata",valuetwo)
          await poolDetails(valuetwo)
        //   hideloader()
        }
      }
      
      const poolDetails = async (value) => {
        // console.log("poolDetails Calc",value)
        setContentLoading(true);
        if (value) {
          const lockedPeriod = (1000 * 60 * 60 * 24 / 1000) * (value);
          const filterData = await searchedValue(poolLimit, lockedPeriod);
        //   console.log("filterData...", filterData[0]);
        //   var RewardTokenInfo = await getRewardTokenInfo();
          var poolday = ((parseInt(lockedPeriod) / ONEDAYINSECONDS))
          // setpoolDays(poolday.toFixed());
    
          var TokenInfo = await UseTokenInfo(irenaLpToken)
          // setTokenInfo(TokenInfo);
    
          var apy = ((filterData[0]?.UmudiPerBlock / 10 ** TokenInfo?.decimals) * Number(poolday)).toFixed(2);
    
        //   console.log("apy", apy, "valueone", valueone, "UmudiPerBlock", filterData[0]?.UmudiPerBlock, " TokenInfo decimals", TokenInfo?.decimals, "RewardTokenInfo",RewardTokenInfo);
    
          const amount = ((apy * (parseInt(userTokenBalance*valueone)/100))) * 10 ** TokenInfo?.decimals;
          const fixedAmount = await toFixedNumber(amount)
        //   console.log("TOFIXEDNUMBER", fixedAmount);
          setPerDayReward(amount);
        //   console.log("apy DATA....", amount, "=====", amount * valueone);
        //   const rewardReturn = await getUSDTprice(fixedAmount, irenaLpToken);
        //   console.log("rewardReturn..", rewardReturn);
        //   setReward(rewardReturn ? (rewardReturn / 10 ** RewardTokenInfo?.decimals).toFixed(14) : "0");
        setReward(fixedAmount/100);
        setContentLoading(false)
        //   hideloader()
        } else {
        //   console.log("NO VALUEs........");
        //   hideloader()
    
        }
    
      }
    
    //   const showloader = async () => {
    //     document.getElementById("loader_div").style.display = "block";
    //   }
    
    //   const hideloader = async () => {
    //     document.getElementById("loader_div").style.display = "none";
    //   }
    const calculate = async() => {
        const stateDetail = selectStakeToken
        const rewardDetail = selectRewardToken
        const amount = valueone * 10 ** stateDetail?.decimal
    
        const getRewardTokens  = await getUSDTprice(amount,stateDetail?.address,rewardDetail?.address);
        console.log("getRewardTokens",getRewardTokens,amount,stateDetail,rewardDetail);
        console.log("percenrage",getRewardTokens/stateDetail?.decimal);
        setReward(getRewardTokens/ parseInt(10 **stateDetail?.decimal));
      }
      
  const selectStakeTokens = async (eventKey) => {
    // console.log(" set selectStakeTokens","----",JSON.parse(eventKey));
    let selectValue = JSON.parse(eventKey);
    if(selectValue.token == "BUSD"){
      // var filteredArray = rewardTokenDetails.filter(function(e) { return e.token !== 'RIA' });
      setRewardListToken(rewardTokenDetails)
      setSelectRewardToken()
    }else if(selectValue.token == "RIA") {
      const found = rewardTokenDetails.find(element => element.token == "RIA");
      setRewardListToken([found])
      setSelectRewardToken()
    }
    }
    return (
        <div>
            <section>
            {/* <div className='showloader minwidth-534 text-center' id="loader_div">
        <div className='loader ' >
          <img src={leaf} className="spin" />
        </div>
      </div> */}
            </section>
        <div className='landing_bg'>
             <div className='home_page'>
            <Header />
            <section className='container container_custom home_sec_1 pt-main-top'>
                <div className='row align-items-center justify-content-end py-5'>
                    <div className='col-12 col-md-5'>
                        <h3 className='text_yellow banner_txt_yel'>Ria Coin Locked <br />Staking</h3>
                        <p className='banner_txt_desc'>You can earn different coins by staking your RIA coins and Busd coins on the RIA staking platform.</p>
                        <div className='d-flex align-items-center mt-4'>
                        {
                                        (accountInfo == "" || accountInfo == null) ?
                                            <button className="nav-link btn_yellow me-3" onClick={() => { setShowWallet(true) }}>Connect Wallet</button> :
                                            <button className="nav-link btn_yellow me-3" onClick={() => { logOut() }}>{`${accountInfo.substring(0, 5)}...${accountInfo.substring(38, 42)}`}</button>
                                    }                        <Link to="/staking" className="nav-link btn_yellow" state={{ from: accountInfo }}>Stake Now</Link>
                       
                        </div>
                    </div>
                    <div className='col-12 col-md-7 mt-5 mt-md-0'>
                        <img src={banner_img} className="img-fluid" />
                        </div>
                </div>
                {/* <div className='row coin_row pb-5'>
                    <div className='col-12 col-sm-6 col-md-3 mb-3'>
                        <div className='d-flex align-items-center'>
                            <img src={logo_coin} className="img-fluid" />
                            <span className='text_yellow ms-2'>Lorem Ipsum</span>
                        </div>
                    </div>
                    <div className='col-12 col-sm-6 col-md-3 mb-3'>
                        <div className='d-flex align-items-center'>
                            <img src={logo_coin} className="img-fluid" />
                            <span className='text_yellow ms-2'>Lorem Ipsum</span>
                        </div>
                    </div>
                    <div className='col-12 col-sm-6 col-md-3 mb-3'>
                        <div className='d-flex align-items-center'>
                            <img src={logo_coin} className="img-fluid" />
                            <span className='text_yellow ms-2'>Lorem Ipsum</span>
                        </div>
                    </div>
                    <div className='col-12 col-sm-6 col-md-3 mb-3'>
                        <div className='d-flex align-items-center'>
                            <img src={logo_coin} className="img-fluid" />
                            <span className='text_yellow ms-2'>Lorem Ipsum</span>
                        </div>
                    </div>
                </div> */}
            </section>
            </div>
           
                
           
        </div>
        <section className='container container_custom home_sec_2'>
                <div className='row align-items-center row_rev'>
                    <div className='col-12 col-md-6 mt-5 mt-md-0'>
                        <div className='card card_shadow'>
                            <div className='card-body px-3 px-lg-5 py-4'>
                                <div>
                            <p className='text_sliver'>Investment Amount</p>
                            <div className='d-flex align-items-center justify-content-between progress_text_div'>
                                <p>{valueone}</p>

                            </div>
                                    <Slider
                                        getAriaLabel={() => "Temperature range"}
                                        value={valueone}
                                        onChange={handleChange}
                                        valueLabelDisplay="auto"
                                        getAriaValueText={valuetextone}
                                        // step={null}
                                        // marks={mark}
                                        max={100}
                                    />

                       
                        </div>

                        <div className='mt-4'>
                            <p className='text_sliver'>Days</p>
                            <div className='d-flex align-items-center justify-content-between progress_text_div'>
                                <p></p>
                                <p>{valuetwo} Day</p>

                            </div>
                           
                            <Slider
                            //   getAriaLabel={() => "Temperature range"}
                            //   value={valuetwo}
                            //   onChange={handleChangeTwo}
                            //   valueLabelDisplay="auto"
                            //   getAriaValueText={valuetexttwo}
                            getAriaLabel={() => "Temperature range"}
                          value={valuetwo}
                          onChange={handleChangeTwo}
                          valueLabelDisplay="auto"
                          getAriaValueText={valuetexttwo}
                          step={null}
                          marks={mark}
                          max={3000}
                            />
                        </div>

                        <div className='mt-4'>

                        <div className='flex_drop_div'>
                             
                             <p>Stake Token :</p>
                          
                              <Dropdown className='dropdwn_trna' onSelect={(eventKey) => {setSelectStakeToken(JSON.parse(eventKey)); selectStakeTokens(eventKey) }}>
                          <Dropdown.Toggle variant="success" id="dropdown-basic1">
                            <img src={eth} className="img-fluid me-1" />
                            {selectStakeToken ? selectStakeToken.token : "Select Stake token"}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {stakeTokens && stakeTokens?.map((value, index) =>
                              (<Dropdown.Item href="javascript:void(0)" eventKey={JSON.stringify(value)}><img src={eth} className="img-fluid imgwht" />{value?.token}</Dropdown.Item>)
                            )}
                          </Dropdown.Menu>
                        </Dropdown> 
                          <p className='ms-2'>Reward Token :</p>
                      <Dropdown className='dropdwn_trna me-3' onSelect={(eventKey) => {setSelectRewardToken(JSON.parse(eventKey)) }}>
                          <Dropdown.Toggle variant="success" id="dropdown-basic">
                            <img src={eth} className="img-fluid me-1" />
                            {selectRewardToken ? selectRewardToken.token : "Select Reward token"}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {rewardListToken && rewardListToken?.map((value, index) =>
                              (<Dropdown.Item href="javascript:void(0)" eventKey={JSON.stringify(value)}><img src={eth} className="img-fluid imgwht" />{value?.token}</Dropdown.Item>)
                            )}
                          </Dropdown.Menu>
                        </Dropdown>       
                             </div>  
                                                   <div className='d-flex align-items-center justify-content-between progress_text_div'>
                                <p className='mid_value'>{contentLoading ?  <PlaceholdingLoader classnames="stripe small-stripe mb-2" parentclassname="loading" />    : 
                                 reward ? reward && (selectStakeToken?.token == 'RIA' && selectRewardToken?.token == 'RIA') ?  
                                 (selectedDayApy / parseFloat(reward) * 100).toFixed(7) :
                                     (selectStakeToken?.token =='BUSD' && selectRewardToken?.token == 'BUSD') ?
                                     (selectedDayApy / parseFloat(reward) * 100).toFixed(7) :
                                 (parseFloat(reward)/selectedDayApy * 100).toFixed(7) : 0} 
                                {selectRewardToken ? selectRewardToken.token : rewardTokenDetails[0].token}</p>
                            </div>
                           
                            <Slider
                              getAriaLabel={() => "Temperature range"}
                              value={reward ? parseInt(reward) : 0}
                              onChange={handleChangeThree}
                              valueLabelDisplay="auto"
                              getAriaValueText={valuetextthree}
                            />
                        </div>
                        <button className="nav-link btn_yellow mt-4" onClick={()=> {calculate()}}>Calculate</button>
                            </div>
                        </div>

                    </div>
                    <div className='col-12 col-md-6 ps-lg-5'>
                        <h3 className='text_yellow banner_txt_yel'>ROI <span className='text-white'>Calculator</span></h3>
                        <p className='banner_txt_desc'>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                        <div className='d-flex align-items-center mt-4'>
                        <Link to="/calculate" className="nav-link btn_yellow">Learn about our ROI</Link>
                       
                        </div>
                    </div>
                </div>
        </section>
        <section className='container container_custom home_sec_3 mt-5'>
                <div className='row align-items-center'>
                   
                    <div className='col-12 col-md-6'>
                        <h3 className='text_yellow banner_txt_yel'>Get Multiple <span className='text-white'>Rewards</span></h3>
                        <p className='banner_txt_desc'>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                        <div className='d-flex align-items-center mt-4'>
                        <Link to="/rewards" className="nav-link btn_yellow">Learn about Rewards</Link>
                       
                        </div>
                    </div>
                    <div className='col-12 col-md-6 mt-5 mt-md-0'>
                      <img src={reward_img} className="img-fluid" />

                    </div>
                </div>
        </section>
        <section className='container container_custom home_sec_4 mt-5'>
        <h3 className='text_yellow banner_txt_yel text-center'>How much can I <span className='text-white'>Earn</span></h3>
       <div className='row'>
        <div className='col-12 col-md-10 col-lg-9 mx-auto'>
        <p className='text_silver_desc text-center'>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>

        </div>
       </div>
              
                <div className='row align-items-center'>
                   
                    <div className='col-12 col-md-6 mt-3'>
                        <p className='banner_txt_desc'>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                       
                    </div>
                    <div className='col-12 col-md-6 mt-3'>
                        <p className='banner_txt_desc'>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                       
                    </div>
                    <div className='col-12'>
                    <div className='d-flex align-items-center mt-4 justify-content-center'>
                        <Link to="/staking" className="nav-link btn_yellow" state={{ from: accountInfo }}>Start Earning Now</Link>
                       
                        </div>
                    </div>
                   
                </div>
        </section>
        <section className='container container_custom home_sec_5 mt-5'>
                <div className='row align-items-center row_rev'>
                   
                  
                    <div className='col-12 col-md-6 mt-5 mt-md-0'>
                      <img src={graph_img} className="img-fluid" />

                    </div>
                    <div className='col-12 col-md-6'>
                        <h3 className='text_yellow banner_txt_yel'>Earn a fixed <span className='text-white'>APY</span></h3>
                        <p className='banner_txt_desc'>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                        <div className='d-flex align-items-center mt-4'>
                        {/* <Link to="/" className="nav-link btn_yellow">Learn about our APY</Link> */}
                       
                        </div>
                    </div>
                </div>
        </section>
        <section className='container container_custom home_sec_6 mt-5 pt-4'>
        <h3 className='text_yellow banner_txt_yel text-center'>Lorem Ipsum <span className='text-white'>Pricing</span></h3>
       <div className='row'>
        <div className='col-12 col-md-10 col-lg-9 mx-auto'>
        <p className='text_silver_desc text-center'>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>

        </div>
       </div>
        
        <div>
            <div className='row mt-5 carousel_home_theme pb-5'>
                <div className='col-12 col-md-12 col-lg-11 col-xl-10 mx-auto'>
                <OwlCarousel className='owl-theme pb-3' loop margin={30} nav {...options}>
               {
                poolLimit && poolLimit.map((val,i)=> 
                <div class='item'>
        <div className='card card_carusel'>
            <img src={star} className="img-fluid star_img_carousel" />
            <div className='card-body py-4'>
            {/* <p className='card_heading_white'>Standard</p> */}
            <p className='text_price'>$<span>{((parseInt(val?.UmudiPerBlock) / 10 ** 18) * Number((val?.lockedPeriod * 1000) / (1000 * 60 * 60 * 24))).toFixed(2)}</span></p>
            <p className='days_grey_price'>{Math.floor(parseInt(val.lockedPeriod * 1000) / (1000 * 60 * 60 * 24))} days</p>
            <ul className='card_ul_car ps-0'>
                <li className='mb-2'>
                    <i className='fa fa-check check_yellow me-2' />

                    
                    <span>10 aliquet dui quis</span>
                </li>
                <li className='mb-2'>
                    <i className='fa fa-check check_yellow me-2' />

                    
                    <span>10 aliquet dui quis</span>
                </li>
                <li className='mb-2'>
                    <i className='fa fa-check check_yellow me-2' />

                    
                    <span>10 aliquet dui quis</span>
                </li>
                <li className='mb-2'>
                    <i className='fa fa-check check_yellow me-2' />

                    
                    <span>10 aliquet dui quis</span>
                </li>
            </ul>
            <div className='d-flex align-items-center text-center mt-4 justify-content-center'>
                        {/* <button className="nav-link btn_yellow_outline">Choose</button> */}
                       
                        </div>
            </div>
        </div>
    </div>
                )
               } 
               


    {/* <div class='item'>
        <div className='card card_carusel'>
            <div className='card-body py-4'>
            <p className='card_heading_white'>Pro</p>
            <p className='text_price_blue'>$<span>2000</span></p>
            <p className='days_grey_price'>10 days</p>
            <ul className='card_ul_car ps-0'>
                <li className='mb-2'>
                    <i className='fa fa-check check_yellow me-2' />

                    
                    <span>10 aliquet dui quis</span>
                </li>
                <li className='mb-2'>
                    <i className='fa fa-check check_yellow me-2' />

                    
                    <span>10 aliquet dui quis</span>
                </li>
                <li className='mb-2'>
                    <i className='fa fa-check check_yellow me-2' />

                    
                    <span>10 aliquet dui quis</span>
                </li>
                <li className='mb-2'>
                    <i className='fa fa-check check_yellow me-2' />

                    
                    <span>10 aliquet dui quis</span>
                </li>
            </ul>
            <div className='d-flex align-items-center text-center mt-4 justify-content-center'>
                        <button className="nav-link btn_yellow_outline">Choose</button>
                       
                        </div>
            </div>
        </div>
    </div> */}

    {/* <div class='item'>
        <div className='card card_carusel'>
            <div className='card-body py-4'>
            <p className='card_heading_white'>Basic</p>
            <p className='text_price'>$<span>100</span></p>
            <p className='days_grey_price'>90 days</p>
            <ul className='card_ul_car ps-0'>
                <li className='mb-2'>
                    <i className='fa fa-check check_yellow me-2' />

                    
                    <span>10 aliquet dui quis</span>
                </li>
                <li className='mb-2'>
                    <i className='fa fa-check check_yellow me-2' />

                    
                    <span>10 aliquet dui quis</span>
                </li>
                <li className='mb-2'>
                    <i className='fa fa-check check_yellow me-2' />

                    
                    <span>10 aliquet dui quis</span>
                </li>
                <li className='mb-2'>
                    <i className='fa fa-check check_yellow me-2' />

                    
                    <span>10 aliquet dui quis</span>
                </li>
            </ul>
            <div className='d-flex align-items-center text-center mt-4 justify-content-center'>
                        <button className="nav-link btn_yellow_outline">Choose</button>
                       
                        </div>
            </div>
        </div>
    </div> */}

    {/* <div class='item'>
        <div className='card card_carusel'>
            <img src={star} className="img-fluid star_img_carousel" />
            <div className='card-body py-4'>
            <p className='card_heading_white'>Standard</p>
            <p className='text_price'>$<span>1000</span></p>
            <p className='days_grey_price'>30 days</p>
            <ul className='card_ul_car ps-0'>
                <li className='mb-2'>
                    <i className='fa fa-check check_yellow me-2' />

                    
                    <span>10 aliquet dui quis</span>
                </li>
                <li className='mb-2'>
                    <i className='fa fa-check check_yellow me-2' />

                    
                    <span>10 aliquet dui quis</span>
                </li>
                <li className='mb-2'>
                    <i className='fa fa-check check_yellow me-2' />

                    
                    <span>10 aliquet dui quis</span>
                </li>
                <li className='mb-2'>
                    <i className='fa fa-check check_yellow me-2' />

                    
                    <span>10 aliquet dui quis</span>
                </li>
            </ul>
            <div className='d-flex align-items-center text-center mt-4 justify-content-center'>
                        <button className="nav-link btn_yellow_outline">Choose</button>
                       
                        </div>
            </div>
        </div>
    </div> */}


    {/* <div class='item'>
        <div className='card card_carusel'>
            <div className='card-body py-4'>
            <p className='card_heading_white'>Pro</p>
            <p className='text_price_blue'>$<span>2000</span></p>
            <p className='days_grey_price'>10 days</p>
            <ul className='card_ul_car ps-0'>
                <li className='mb-2'>
                    <i className='fa fa-check check_yellow me-2' />

                    
                    <span>10 aliquet dui quis</span>
                </li>
                <li className='mb-2'>
                    <i className='fa fa-check check_yellow me-2' />

                    
                    <span>10 aliquet dui quis</span>
                </li>
                <li className='mb-2'>
                    <i className='fa fa-check check_yellow me-2' />

                    
                    <span>10 aliquet dui quis</span>
                </li>
                <li className='mb-2'>
                    <i className='fa fa-check check_yellow me-2' />

                    
                    <span>10 aliquet dui quis</span>
                </li>
            </ul>
            <div className='d-flex align-items-center text-center mt-4 justify-content-center'>
                        <button className="nav-link btn_yellow_outline">Choose</button>
                       
                        </div>
            </div>
        </div>
    </div> */}

    {/* <div class='item'>
        <div className='card card_carusel'>
            <div className='card-body py-4'>
            <p className='card_heading_white'>Basic</p>
            <p className='text_price'>$<span>100</span></p>
            <p className='days_grey_price'>90 days</p>
            <ul className='card_ul_car ps-0'>
                <li className='mb-2'>
                    <i className='fa fa-check check_yellow me-2' />

                    
                    <span>10 aliquet dui quis</span>
                </li>
                <li className='mb-2'>
                    <i className='fa fa-check check_yellow me-2' />

                    
                    <span>10 aliquet dui quis</span>
                </li>
                <li className='mb-2'>
                    <i className='fa fa-check check_yellow me-2' />

                    
                    <span>10 aliquet dui quis</span>
                </li>
                <li className='mb-2'>
                    <i className='fa fa-check check_yellow me-2' />

                    
                    <span>10 aliquet dui quis</span>
                </li>
            </ul>
            <div className='d-flex align-items-center text-center mt-4 justify-content-center'>
                        <button className="nav-link btn_yellow_outline">Choose</button>
                       
                        </div>
            </div>
        </div>
    </div> */}

 
</OwlCarousel>
                </div>
            </div>
     
        </div>
              
        </section>
         <Footer />
        {wallet && <WalletModal onDismiss={()=> setShowWallet(false)} />}
        </div>
      
        // <section className='land pb-5'>
        //     <div className='showloader minwidth-534 text-center' id="loader_div">
        //         <div className='loader ' >
        //             <img src={loader} className="spin" />
        //             <img src={leaf} className="leaf" />
        //         </div>

        //     </div>
        //     <header className='container'>
        //         <div className='d-flex align-items-center justify-content-between'>
        //         <img src={logo} />
        //         <Link to="/usdi" className='link_to_stake'>Stake USDI</Link>
        //         </div>
        //     </header>
        //     <div><Toaster /></div>
        //     <div className='container'>


        //         <Tab.Container id="left-tabs-example" activeKey={key}
        //             onSelect={(k) => setKey(k)}>
        //             <Row className='align-items-center'>
        //                 <Col lg={3} className="text-end">
        //                     <Nav variant="pills" className="flexcolumn">
        //                         <Nav.Item className='completed'>
        //                             <Nav.Link eventKey="first" >1</Nav.Link>
        //                         </Nav.Item>
        //                         <Nav.Item id="tabid" className=''>
        //                             <Nav.Link eventKey="second" >2</Nav.Link>
        //                         </Nav.Item>
        //                         <Nav.Item id="tabid" className=''>
        //                             <Nav.Link eventKey="third" onClick={getData}>3</Nav.Link>
        //                         </Nav.Item>
        //                     </Nav>
        //                 </Col>
        //                 <Col lg={9}>
        //                     <Tab.Content>
        //                         <Tab.Pane eventKey="first">

        //                             <div className='tab-cont'>

        //                                 <div className='text-center'>

        //                                     <div className='col-md-7 minwidth-534'>
        //                                         <div className='head text-center'>
        //                                             <h2 className='upper'>Connect wallet</h2>
        //                                             <p>Welcome to Irena Green Energy World</p>
        //                                         </div>
        //                                         <div className='circlesec'>
        //                                             <div className='centertext'>
        //                                                 <img src={center} className="leafimg" />
        //                                             </div>
        //                                             <img src={disabled} className="stepbg" />
        //                                             <div className='steps'>
        //                                                 <button onClick={() => setTabKey('second')} className='step1btn' >
        //                                                     <img src={file} />
        //                                                     <p>Connect</p>
        //                                                     <img src={arrow} className="arrow" />

        //                                                 </button>
        //                                             </div>
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </Tab.Pane>
        //                         <Tab.Pane eventKey="second">
        //                             <div className='tab-cont'>
        //                                 <div className='text-center'>
        //                                     <div className='col-md-7 minwidth-534'>
        //                                         <div className='head text-center'>
        //                                             <h2 className='upper'>Connect wallet</h2>
        //                                             <p>Irena Green Energy World</p>
        //                                         </div>
        //                                         <div className='circlesec'>
        //                                             <div className='centertext'>
        //                                                 <img src={center} className="leafimg" />
        //                                             </div>
        //                                             <img src={disabled} className="stepbg" />
        //                                             <div className='steps1'>
        //                                                 <button onClick={async () => { MetaMask(); await getData(); showloader(); addtabclass() }} className='step1btn'>
        //                                                     <img src={meta} className="meta" />
        //                                                     <p>Meta Mask</p>
        //                                                     <img src={arrow} className="arrow" />
        //                                                 </button>
        //                                             </div>
        //                                             <div className='steps2'>
        //                                                 <button onClick={async () => { enableWallet(); await getData(); addtabclass(); }} className='step1btn'>
        //                                                     <img src={wall} className="wall" />
        //                                                     <p>Wallet Connect</p>
        //                                                     <img src={arrow} className="arrow" />
        //                                                 </button>
        //                                             </div>
        //                                             <div className='steps3 steps_new1'>
        //                                                 <button onClick={async () => { MetaMask(); await getData(); showloader();addtabclass() }} className='step1btn'>
        //                                                     <img src={trust} className="wall" />
        //                                                     <p>DApps</p>
        //                                                     <img src={arrow} className="arrow" />
        //                                                 </button>
        //                                             </div>
        //                                             <div className='steps4 steps_new1'>
        //                                                 <button onClick={async () => { Binance(); await getData(); showloader();addtabclass() }} className='step1btn'>
        //                                                     <img src={binance} className="wall" />
        //                                                     <p>Binance Wallet</p>
        //                                                     <img src={arrow} className="arrow" />
        //                                                 </button>
        //                                             </div>
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </Tab.Pane>
        //                         <Tab.Pane eventKey="third" className='laststep'>

        //                             <OwlCarousel items={1} dots={false} nav={true} className="owl-theme owl_coin minwidth-534">

        //                                 {poolLimit
        //                                     && poolLimit.map((val, index) => <Stakecard datas={val} pid={index} lpsymbols={lpsymbol[index]} accountInfo={accountInfo} />
        //                                     )}
        //                             </OwlCarousel>
        //                         </Tab.Pane>
        //                     </Tab.Content>
        //                 </Col>
        //             </Row>
        //         </Tab.Container>

        //     </div>
        // </section>
    )
}

export default Home
