export const  sleep = (ms) => 
{
    new Promise(resolve => setTimeout(resolve, ms))
}

export const FARMS = [{
    pid: 0,
    lpAddress: "0xF5349B154829a8Eceb99FDdb90E81E53dB61a8dD",
    lpSymbol: "TMAT-XDC LP",
    tokenSymbol: "TMAT",
    tokenAddress: "0x4fb5519aD7bC90B17de1343894FeFC9e320bB745",
    quoteTokenSymbol: "XDC",
    quoteTokenAddress: "0xe78E01449fC8Efd222047A28005Da3B1058BEE28",
    isTokenOnly: false
},
{
    pid: 1,
    lpAddress: "0x0B28Fd83f6e050dB22eA646BC9609f5d4D590E19",
    lpSymbol: "XSP-XDC LP",
    tokenSymbol: "XSP",
    tokenAddress: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B",
    quoteTokenSymbol: "XDC",
    quoteTokenAddress: "0xe78E01449fC8Efd222047A28005Da3B1058BEE28",
    isTokenOnly: false
},
{
    pid: 2,
    lpAddress: "0xF5349B154829a8Eceb99FDdb90E81E53dB61a8dD", // native - usd
    lpSymbol: "TMAT",
    tokenSymbol: "TMAT",
    tokenAddress: "0x4fb5519aD7bC90B17de1343894FeFC9e320bB745",
    quoteTokenSymbol: "XDC",
    quoteTokenAddress: "0xe78E01449fC8Efd222047A28005Da3B1058BEE28",
    isTokenOnly: true
}
// ,
// {
//     pid: 3,
//     lpAddress: "",
//     lpSymbol: "WXDC",
//     tokenSymbol: "WXDC",
//     tokenAddress: "",
//     quoteTokenSymbol: "",
//     quoteTokenAddress: "",
//     isTokenOnly: true
// }
]

export const stakeTokens = [
    
    {
        token: "BUSD",
        address: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
        decimal: 18
    },
    {
        token: "RIA",
        address: "0xebdf132b13653969717c51517fa850e6aeff3a80",
        decimal: 18
    }
]

export const rewardTokenDetails = [
    {
        token: "RIA",
        address: "0xebdf132b13653969717c51517fa850e6aeff3a80"
    },
    {
        token: "BUSD",
        address: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
    },
    {
        token: "ETH",
        address: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8"
    },
    {
        token: "BNB",
        address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
    },
    {
        token: "SHIBA",
        address: "0x2859e4544C4bB03966803b044A93563Bd2D0DD4D"
    },
    // {
    //     token: "DOGE",
    //     address: "0xbA2aE424d960c26247Dd6c32edC70B295c744C43"
    // },
    // {
    //     token: "IRENA",
    //     address: "0x9eeB6c5ff183E6001c65a12D70026b900AE76781"
    // },
    // {
    //     token: "TWT",
    //     address: "0x4b0f1812e5df2a09796481ff14017e6005508003"
    // },
    // {
    //     token: "MATIC",
    //     address: "0xCC42724C6683B7E57334c4E856f4c9965ED682bD"
    // },
    // {
    //     token: "LINK",
    //     address: "0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD"
    // },
    // {
    //     token: "UNI",
    //     address: "0xBf5140A22578168FD562DCcF235E5D43A02ce9B1"
    // }

    // {
    //     token: "RIA",
    //     address: "0xbCf448aa88d80C1B7bb8Fb5A1e68cd4B6c11c354",
    //     pid: 0
    // },
    // {
    //     token: "LBM",
    //     address: "0x4849027a11E584916968Ccd991eC4Ce956Ef7Fef",
    //     pid: 1
    // },
    // {
    //     token: "MAT",
    //     address: "0x2Bbeaf7BB69d2296Aa1d09c9198a111f1A2E6fD9",
    //     pid: 2
    // },
    // {
    //     token: "DEMO",
    //     address: "0x4e5687Bcb8172081fd926B105B62E2CcBd542Bbf",
    //     pid: 3
    // }

]

export const irenaLpToken = "0xDFCd0b91AE2BB34a90D9b4970Dc113DFaf25004d"