import React, { useEffect, useState } from 'react';
import Header from './Header'
import {useNavigate } from "react-router-dom";
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import { getAccount } from "../hooks/useAccount"
import eth_bal from '../images/eth_bal.png';
// import eth_bal_price from '../images/eth_bal_price.png';
// import eth from '../images/eth.png';
import ClaimModal from "./ClaimModal";
import { getRewardTokenInfo, getUser, getUserReward, Claim, getUSDTprice, getPoolLength, getPoolInfoLimit, FARMS,plan } from "../hooks/UseStake"
import { sleep, UseTokenInfo, checkIsApproved, approveContract, getUserBalance } from "../hooks/UseContract"
import { ONEDAYINSECONDS } from '../config/env';
import toast, { Toaster } from 'react-hot-toast';
import leaf from '../images/leaf.png'
import LockedStakingTable from './LockedStakingTable'
import PlaceholdingLoader from './PlaceholdingLoader';


const Rewards = () => {
  var navigate = useNavigate()
  // const [claim, setShowClaim] = useState(false)
  const [pid, setPid] = useState();
  const [poolLength, setPoolLength] = useState(0);
  const [poolLimit, setPoolLimit] = useState([]);
  const [userStakedAmount, setUserStakedAmount] = useState(0);
  const [apy, setApy] = useState();
  const [step, setStep] = useState(1);
  const [tokenInfo, setTokenInfo] = useState();
  const [accountInfo, setAccountInfo] = useState("");
  const [pending, setPending] = useState(0)
  const [rewardTokenInfo, setRewardTokenInfo] = useState({});
  const [userstatedate, setuserstatedate] = useState(0)
  const [selectDay, setSelectDay] = useState(false)
  // const [data, setData] = useState([])
  const [loading, setLoading] = useState(false);
  const [pagination, setpagination] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(15);
  const [setloader, showSetloader] = useState(false);
  const [poolDetail,setPoolDetails] = useState([]);
  const [record, setRecord] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [contentLoading, setContentLoading] = useState(false);
  const [status, setStatus] = useState('')
  const [planArr,setPlanArr] = useState();
  const [planIndex,setPlanIndex] = useState();
  useEffect(() => {
    showloader()
    // setContentLoading(true)
    getData();
    setAccountInfo(getAccount())
  }, [
    poolDetail == "" ? poolDetail : "" 
  ])

  
  // const getData = async () => {
  //   var data = await getPoolLength()
  //   // console.log("data", data);
  //   if (data) setPoolLength(data);
  //   var pool = await getPoolInfoLimit(0, data)
  //   if (pool) {
  //     // console.log(pool, 'pool')
  //     setPoolLimit(pool);
  //     setLoading(true);
  //     // hideloader()
  //   }
  // }

 const getData = async () => {
  setLoading(false);
    var data = await getPoolLength()
    // console.log("data", data);
    if (data) setPoolLength(data);
    var pool = await getPoolInfoLimit(0, data)
    if (pool) {
      setPoolLimit(pool);
      let poolArr = [];
      let getUserDetails = [];
      let newPoolArr = [];
      let planIndex = [];
      for (let [index, value] of poolLimit.entries()) { 
        var getuser = await getUser(index, getAccount());
       if(getuser?.amount > 0 ){
        // console.log("getuser?.amount",getuser?.amount);
        getUserDetails.push(getuser)
        newPoolArr.push(pool[index]);
        planIndex.push(index)
      // console.log("planIndex_planIndex",index);
        var poolDetails = await plan(getuser?.PlanId);
        poolArr.push(poolDetails);
       }else{console.log("  .   ")}
        
      }
      // console.log("planIndex_planIndex",planIndex);
      // console.log("POOLARR",poolArr,"-----","poolArr.length",poolArr.length,poolLimit,getuser)
      setPoolDetails(poolArr)
      setUserDetails(getUserDetails)
      setPlanArr(newPoolArr);
      setPlanIndex(planIndex)
      setLoading(true);
     hideloader()  
    }
    //  setContentLoading(false)
  }


  var indexOfLastRecord = currentPage * recordsPerPage;
  var indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  var currentRecords = poolLimit.slice(indexOfFirstRecord,indexOfLastRecord);

  var nPages = Math.ceil(poolLimit.length / recordsPerPage)
  var pageNumbers = [...Array(nPages + 1).keys()].slice(1)
  
  // console.log("indexOfLastRecord : ", indexOfLastRecord, "----", "indexOfFirstRecord", indexOfFirstRecord, "-----", "currentRecords", currentRecords)

  const nextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1)
  }
  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1)
  }

  const poolDetails = async (e) => {
    showloaderCard()
    setPid(e.target.value)
    const getuser = await getUser(e.target.value, getAccount());

    const findPoolDetails = poolDetail[e.target.value];
    const data = planArr[e.target.value]
    // console.log("poolDetails................................................",data)
    const userDetail = userDetails[e.target.value];
    setUserStakedAmount(userDetail?.amount);
    const apypercentage = ((findPoolDetails?.UmudiPerBlock / 10 ** 18) * Number(parseInt(findPoolDetails?.lockedPeriod) / ONEDAYINSECONDS)).toFixed(2)
    setApy(apypercentage)

    var TokenInfo = await UseTokenInfo(data?.lpToken)
    setTokenInfo(TokenInfo);
    var RewardTokenInfo = await getRewardTokenInfo(data?.rewardToken);
    setRewardTokenInfo(RewardTokenInfo);
    
    const datee = new Date(parseInt(getuser?.userLockedPeriod) * 1000);
    const unstakeDate = datee.toLocaleDateString();
    if (getuser?.userLockedPeriod > 0)
      setuserstatedate(unstakeDate)
    const userRewardPlanId = planIndex[e.target.value]
    var UserReward = await getUserReward(userRewardPlanId, accountInfo)
    console.log("UserReward",UserReward);
    var pending = (parseInt(UserReward || 0) + parseInt(getuser?.pendingRewards || 0));

    var usdtprice = await getUSDTprice(pending, data?.lpToken,data?.rewardToken);
    
    setPending((usdtprice / 10 ** RewardTokenInfo?.decimals).toFixed(14) ? (usdtprice / 10 ** RewardTokenInfo?.decimals).toFixed(14) : "0")
    setSelectDay(true)
    hideloaderCard()
  }

  const Withdraw = async () => {
    if (selectDay == false) {
      toast.error("Please Select Date First!", {
        duration: 2000,
        style: {
          minWidth: '300px',
          minHeight: '55px'
        }
      })
    } else if (userStakedAmount == 0) {
      toast.error("Please Stake First!", {
        duration: 2000,
        style: {
          minWidth: '300px',
          minHeight: '55px'
        }
      })
    }
    else {
      const sendData = { tokenInfo: tokenInfo, userstatedate: userstatedate, pid: pid, userStakedAmount: userStakedAmount }
      navigate(`/withdraw`, { state: sendData })
    }
  }

  const showloaderCard = async () => {
    showSetloader(true);
  }

  const hideloaderCard = async () => {
    showSetloader(false);
  }
  const showloader = async () => {
    document.getElementById("loader_div").style.display = "block";
  }

  const hideloader =  () => {
    document.getElementById("loader_div").style.display = "none";
  }

  const claimRewards  = async(pid) => {
    // console.log("USERCLAIM",pid);
    const userclaim = await Claim(getAccount(),pid);
    // console.log("USERCLAIM", userclaim);
  }

  const showRecord =(data) => {
    // console.log("SHOWW",data)
      setRecord(data);
  }
  return (
    <section>
      <div className='showloader minwidth-534 text-center' id="loader_div">
        <div className='loader' >
          <img src={leaf} className="spin" />
          {/* <img src={leaf} className="leaf" /> */}
        </div>
      </div>
      <div className='innerpages rewardspage pb-4'>
        {/* {claim && <ClaimModal onDismiss={() => setShowClaim(false)} />} */}
        <Header />
        {/* {console.log(poolDetail,"POOLDETAILS")} */}
        <div className='container container_custom mt-5 pt-main-top'>
          <div className='headsec mb-3'>
            <div>
              <h3 className='headtitle'>Reward</h3>
            </div>
            {/* <div>
                    <Link to="/calculate" className='headright'>ROI Calculator</Link>
                 </div> */}
          </div>
          <div className='cardsec'>
            <div className='firstcard mb-4'>
              <div class="card shadowstyle">
                <div className={setloader ? 'showloadercard minwidth-534 text-center' : 'showloadercard minwidth-534 text-center d-none'} id="loader_div_card">
                  <div className='loader_card' id="loader_card">
                    <img src={leaf} className="spin" />
                  </div>

                </div>
                <div class="card-body">
                  {/* <div className='row firstrow'>
                    <div className='col-12 col-md-8 col-lg-10 mb-3 mb-md-2'>
                      <form>
                        <div class="form-group"> <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" value="Ox1 f672 2bADB0.9791CD9A37ea913f2BAC38d01279" />
                        </div>
                      </form>
                    </div>
                    <div className='col-12 col-md-4 col-lg-2 mb-2'>
                      <button className="btn_yellow">Calculate</button>
                    </div>
                  </div> */}
                  <div className='row my-2 mb-4 my-md-4 px-2'>
                    <Badge className='bange_long'><span>Current balance may differ from last balance in the table due to rounding.</span></Badge>
                  </div>

                  <div className='row balancerow'>
                    <div className='col-sm-6 col-md-3 mb-3'>
                      <div>
                        <p className='bal_title'>Staked {tokenInfo && tokenInfo?.symbol}</p>
                        <p className='bal_value'><img src={eth_bal} className="img-fluid" /> {userStakedAmount && (parseInt(userStakedAmount) / 10 ** parseInt(tokenInfo && tokenInfo?.decimals)).toFixed(3)}</p>
                        {/* <p className='bal_last'>${userStakedAmount}</p> */}
                      </div>
                    </div>
                    <div className='col-sm-6 col-md-3 mb-3'>
                      <div>
                        <p className='bal_title'>APY</p>
                        <p className='bal_value'><img src={eth_bal} className="img-fluid" /> {apy}%</p>
                        {/* <p className='bal_last'>$0</p> */}
                      </div>
                    </div>
                    <div className='col-sm-6 col-md-3 mb-3'>
                      <div>
                        <p className='bal_title'>Earned {rewardTokenInfo && rewardTokenInfo?.symbol}</p>
                        <p className='bal_value'>{pending}</p>
                      </div>
                    </div>
                    {/* <div className='col-sm-6 col-md-3 mb-3'>
        <div>
          <p className='bal_title'>stETH price</p>
          <p className='bal_value'>$ 1,717.92</p>
          <p className='bal_last'><img src={eth_bal_price} className="img-fluid" /> 0.095245661</p>
        </div>
      </div> */}
                    <div className='col-sm-6 col-md-3 mb-3'>
                      <div>
                        <p className='bal_title'>Select Days</p>
                        <Form.Select className='selct_neew_style' onChange={(e) => { poolDetails(e) }}>
                          {/* {console.log("one...",poolDetail,"poolDetail-length :",poolDetail.length)} */}
                          <option value={"default"} disabled selected> Choose Days</option>

                          {
                            loading ? poolDetail && poolDetail.map((val, index) =>
                              <option value={index}>{Math.floor(parseInt(val?.lockedPeriod * 1000) / (1000 * 60 * 60 * 24))} Days</option>)
                              :
                              <option value={"default"} disabled> Fetching Data.......</option>
                          }

                        </Form.Select>
                      </div>
                    </div>

                  </div>
                  <div className='rewardsbtnsec mt-md-3 '>
                  {/* disabled={pending <= 0} */}
                    <button onClick={() => {claimRewards(pid)}} className="btn_yellow me-3" disabled={pending <= 0}>Claim</button>
                    <button onClick={() => { Withdraw() }} className="btn_yellow me-3">Withdraw</button>
                  </div>
                </div>
              </div>
            </div>
            <div className='secondcard mt-5 mb-4'>
              <div class="card shadowstyle">
                <div class="card-body">
                  <div className='headsec mb-3'>

                    <h3 className='headtitle'>Investment</h3>

                  </div>
                  <div className='tablesec'>
                    <table class="table">

                      <thead>

                        <tr>
                          {/* <th scope="col">#</th> */}
                          <th scope="col">Staked Days </th>
                          <th scope="col">Return APY </th>
                          <th scope="col">Stake Amount</th>
                          <th scope="col">Earnings </th>
                          <th scope="col">Stake Token</th>
                          <th scope="col">Reward </th>
                          <th scope="col">UnStake</th>
                          <th scope="col">Action</th>

                        </tr>

                      </thead>
                      {
                      
                      
                      //   contentLoading ?   <tbody>
                      //   <tr>
                      //   <td colSpan={9}>
                      //   <PlaceholdingLoader classnames="stripe long-stripe mb-2" parentclassname="loading" subclassnames="flex_content" />
                      //   <PlaceholdingLoader classnames="stripe medium-stripe mb-2" parentclassname="loading" subclassnames="flex_content" />

                      //   </td>
                      // </tr>
                      // </tbody> : 
                        currentRecords && currentRecords.map((val, index) =>
                        // (index == 0  || index == 1 || index == 2 || index == 3 ? 
                          <LockedStakingTable datas={val} pid={poolLimit.indexOf(val)} type={"reward"} accountInfo={accountInfo} hideloader={() => {hideloader()}} index={index} showRecord={showRecord}/>
                          // : <></>
                        // )
                        )
                      }
                    </table>
                    <div><center className='text-white'>{poolLimit?.length > 0 ? "": "NO RECORD FOUND"}</center></div>
                  </div>
                </div>
              </div>
            </div>
            {record == false ? "" :
            <div className='paginationsec'>

              <nav aria-label="...">
                <ul class="pagination">

                  <li class={currentPage != 1 ? "page-item" : "page-item disabled"}>
                    <a class="page-link" href="javascript:void(0)" onClick={prevPage}><i class="fa fa-angle-left" aria-hidden="true"></i></a>
                  </li>

                  {pageNumbers && pageNumbers.map((pgNumber) =>
                    <li class="page-item active" key={pgNumber}>
                      <a onClick={() => setCurrentPage(pgNumber)} className='page-link' href='javascript:void(0)' > {pgNumber} </a>
                    </li>
                  )}
                  {/* {console.log("currentPage", currentPage, "-----", "nPages--", nPages)} */}
                  <li class={currentPage != nPages ? "page-item" : "page-item disabled"}>
                    <a class="page-link" href="javascript:void(0)" onClick={nextPage}><i class="fa fa-angle-right" aria-hidden="true" ></i></a>
                  </li>
                </ul>
              </nav>
            </div>
}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Rewards
